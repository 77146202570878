import { Environment } from './environment.model';
import { appVersion } from './app-version';
import { supportedLanguages } from './supported-languages';

export const environment: Environment = {
    production: true,
    version: appVersion,
    instana: true,
    app: {
        defaultLanguage: 'de-DE',
        supportedLanguages,
        roles: {
            admin: 'Serviceconfigurator.Admin',
            consumer: 'Serviceconfigurator.Default',
        },
        multiValenceForbiddenSalesOrgs: ['6000'],
        shopEnabledSalesOrgs: ['0500'],
        contractMinDateCurrentYearSalesOrg: ['6200'],
    },
    auth: {
        appId: 'service-configurator',
        baseUrl: 'https://api-staging.viessmann.com',
        usersUrl: 'https://api-staging.viessmann.com/users/v1/users/me',
        registerBaseUrl: 'https://account-staging.viessmann.com',
    },
    http: {
        apiBaseUrl: 'https://api-staging.viessmann.com/backend/service-configurator/v1/',
        translations:
            'https://api-staging.viessmann.com/translations/v2/phraseapp-proxy/projects/9209f7dba36cf4da6cf12ca1b499cdcb/locales/',
        dswTranslations:
            'https://api-staging.viessmann.com/translations/v2/phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/',
        users: 'https://api-staging.viessmann.com/users/v1/users/',
        equipment: {
            search: {
                employee: 'https://fiori-test.viessmann.com/fiori#ZCS_EQUI_SEARCH_BY_ADDRESS2-display',
                customer:
                    'https://api-staging.viessmann.com/b2b-service-and-maintenance/heating-systems-overview/v1/b2b/heating-systems',
                consumer:
                    'https://api-staging.viessmann.com/b2b-service-and-maintenance/heating-systems-overview/v1/b2c/heating-systems',
            },
            change: {
                employee: 'https://fiori-test.viessmann.com/fiori#ZCS_EQUI_MASTER_DATA_SHEET-display?equipment=',
                customer: 'https://heating-system-registration-b2b-staging.viessmann.com/open/',
            },
        },
        shopUrl: 'https://shop-stage.viessmann.com/',
        statusPage: 'https://api-staging.viessmann.com/status/v1/apps/prt8wzsb16j3/summary',
        checkoutUrl: 'https://checkout-staging.viessmann.com',
        faq: {
            customer: {
                '0500': 'https://partnerportal.viessmann.com/de/de/support/faq/service-configurator.html',
            },
            endcustomer: {
                '0500': 'https://www.viessmann.de/de/kontakt/anfragen/vertragsangebot-faq.html',
            },
            default: 'https://support.viessmann.com/?page=1&pageSize=8',
        },
        companyUrl: 'https://api-staging.viessmann.com/company/v3/companies/',
        quotation: 'https://api-staging.viessmann.com/quotation/v1/',
    },
    configit: {
        imageUrl: 'https://api-staging.viessmann.com/backend/service-configurator/v1/configuration/images',
        languages: ['de'],
        salesAreaName: 'Germany',
        usage: '5',
        plant: '0500',
    },
    quest: {
        context: 'quest',
        project: 'ServiceConfig',
    },
};
