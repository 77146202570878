import { Injectable } from '@angular/core';
import {
    ConfigitAssignment,
    ConfigitAssignmentResp,
    ConfigitConfiguration,
    ConfigitConfigurationAndProducts,
    ConfigitConfigurationResp,
    ConfigitEnvSettings,
    ConfigitSettings,
    ConfigitTemplate,
    ConfigitTemplateResp,
} from '../models/configit.model';
import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppService } from './app-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ConfigitApiService {
    constructor(private http: HttpClient, private appService: AppService) {}

    public getSettings(): Observable<ConfigitSettings> {
        return this.http.get<ConfigitSettings>(`${environment.http.apiBaseUrl}configuration/settings`);
    }

    config(material: string): ConfigitEnvSettings {
        const conf: ConfigitEnvSettings = environment.configit;
        const language =
            material === environment.quest.project
                ? this.appService.getLanguage().replace('-', '_')
                : this.appService.getLanguageOnly();
        conf.plant = this.appService.getSalesOrg();
        conf.languages = [language];
        conf.salesAreaName = this.appService.getSalesAreaName();
        conf.salesAreaId = this.appService.getSalesAreaId();
        return conf;
    }

    /**
     * Gets template
     *
     * @param material Configit material
     * @returns Template structure (first)
     */
    getTemplate(material: string): Observable<ConfigitTemplate> {
        const params = this.templateParams(material);

        return this.http
            .post(`${environment.http.apiBaseUrl}configuration/getMaterialTemplateData`, params)
            .pipe(map((resp: ConfigitTemplateResp) => resp.templates[0]));
    }

    /**
     * Gets configuration
     *
     * @param data Additional params replacing defaults from environment.configit
     * @param assignments Initial assignments
     * @returns Configuration structure (extracted from root)
     */
    getConfiguration(data: any = {}, assignments?: ConfigitAssignment[]): Observable<ConfigitConfigurationAndProducts> {
        const params = this.configurationParams(data);
        return this.http.post(`${environment.http.apiBaseUrl}configuration/getDefaultConfiguration`, params).pipe(
            concatMap((resp: ConfigitConfigurationResp) => {
                return this.http.post(
                    `${environment.http.apiBaseUrl}configuration/getFromExistingConfiguration`,
                    this.configurationParams(
                        data,
                        this.mergeAssignments(
                            resp.root.configuration.newAssignments.filter((a) => a.isDefault),
                            assignments
                                ?.map((a) => {
                                    if (a.isUserAssignment === undefined) {
                                        a.isUserAssignment = !a.isDefault;
                                    }
                                    return a;
                                })
                                .filter((a) => a.isUserAssignment)
                        )
                    )
                );
            }),
            map((resp: ConfigitConfigurationResp) => ({
                configuration: resp.root.configuration,
                bomItems: resp.root.bomItems,
            }))
        );
    }

    private mergeAssignments(lhs: ConfigitAssignment[], rhs?: ConfigitAssignment[]) {
        return [
            ...lhs.map((assignment) => rhs?.find((a) => a.variableName === assignment.variableName) || assignment),
            ...rhs?.filter((a) => !lhs.some(({ variableName }) => a.variableName === variableName)),
        ];
    }
    /**
     * Submits update value
     *
     * @param name Material name
     * @param variable Variable name
     * @param value Variable value
     * @param existing Existing assignments
     * @param data Additional params replacing defaults form environment.configit
     * @returns Configuration data
     */
    setAssignment(
        name: string,
        variable: string,
        value: any,
        existing?: any,
        data?: any
    ): Observable<ConfigitConfiguration> {
        const params = this.updateParams(name, variable, value, existing, data);
        return this.http.post(`${environment.http.apiBaseUrl}configuration/updateValues`, params).pipe(
            map((resp: ConfigitAssignmentResp) => this.checkErrors(variable, value, resp)),
            map((resp: ConfigitAssignmentResp) => resp.bomDeltaConfigurationData.configurationData)
        );
    }

    /**
     * Submits configuration
     *
     * @param material Material name
     * @param assignments Existing assignments
     * @param order Order identifier
     * @param captcha Captcha response
     * @param recommend Recommend flag
     * @returns Configuration structure (extracted from root)
     */
    submitConfiguration(
        material: string,
        assignments?: any,
        order?: string,
        captcha?: string,
        recommend?: boolean
    ): Observable<ConfigitConfiguration> {
        const data = { material, captcha, order, recommend, submit: 'true' };
        const params = this.configurationParams(data, assignments);

        return this.http
            .post(`${environment.http.apiBaseUrl}configuration/submit`, params)
            .pipe(map((resp: ConfigitConfigurationResp) => resp.root.configuration));
    }

    protected checkErrors(variable: string, value: any, resp: ConfigitAssignmentResp): ConfigitAssignmentResp {
        // as error detected, move generic/unknown error to configuration structure
        if (resp.assignmentError) {
            resp.bomDeltaConfigurationData = {
                configurationData: {
                    uiGroupStates: [],
                    // put invalid value to the state as can be consumed by transformer to keep in UI
                    variableStates: [
                        {
                            fullyQualifiedName: variable,
                            invalidMessage: 'error.invalid',
                            invalidValue: value,
                        },
                    ],
                    // but remove from assignments if exists (f.e. previous valid)
                    assignmentsToRemove: [
                        {
                            variableName: variable,
                        },
                    ],
                },
            };
        }

        return resp;
    }

    /**
     * Constructs template get params - combining given params and defaults from config
     *
     */
    protected templateParams(material: string): any {
        const config = this.config(material);

        return {
            name: material,
            languages: config.languages,
            salesAreaId: config.salesAreaId,
            salesAreaName: config.salesAreaName,
        };
    }

    /**
     * Constructs configuration get params - combining given params and defaults from config
     *
     */
    protected configurationParams(params: ConfigitEnvSettings = {}, assignments: any[] = []): any {
        const config = this.config(params.material);

        return {
            plant: params.plant || config.plant,
            usage: params.usage || config.usage,
            name: params.material || config.material,
            languages: params.languages || config.languages,
            salesAreaName: params.salesAreaName || config.salesAreaName,
            salesAreaId: params.salesAreaId || config.salesAreaId,
            rootConfiguration: {
                existingAssignments: assignments,
                materialName: params.material || config.material,
            },
            environment: {
                rootEnvironment: {
                    preselect: params.preselect,
                    submit: params.submit,
                    order: params.order,
                    captcha: params.captcha,
                    recommend: params.recommend && 'true',
                    salesArea: {
                        salesOrganization: params.plant || config.plant,
                        distributionChannel: '01',
                    },
                },
            },
        };
    }

    private getUpdatedValues(
        variable: string,
        value: string | string[],
        assignments: any[]
    ): { added: string[]; removed: string[] } {
        let added = [];
        let removed = [];
        const variableAssignments = assignments.filter((a) => a.variableName === variable);
        if (Array.isArray(value)) {
            // multi value question (e.g. checklist),
            // check if the item was added or removed by comparing with current assignments
            removed = variableAssignments.filter((item) => !value.includes(item.valueName)).map((r) => r.valueName);
            // value array has all selected item ids, only send the latest value,
            // that is, the value that does not occur in any of the current assignments
            added = value.filter((id) => !variableAssignments.some((item) => item.valueName === id));
        } else {
            // normal single value question
            if (value === undefined || value === null || value === '') {
                removed = variableAssignments.map((a) => a.valueName);
            } else {
                added = [value];
            }
        }
        return { added, removed };
    }

    protected updateParams(
        name: string,
        variable: string,
        value: string,
        assignments: any[] = [],
        params: ConfigitEnvSettings = {}
    ) {
        const config = this.config(name);

        const { added, removed } = this.getUpdatedValues(variable, value, assignments);

        const updatedValues = [
            ...added.map((add) => ({ value: add, updateType: 1 })),
            ...removed.map((rem) => ({ value: rem, updateType: 0 })),
        ];

        return {
            name,
            languages: params.languages || config.languages,
            salesAreaName: params.salesAreaName || config.salesAreaName,
            salesAreaId: params.salesAreaId || config.salesAreaId,
            plant: params.plant || config.plant,
            assignment: {
                existingAssignments: assignments,
                itemId: '',
                newAssignment: {
                    action: 'updateValues',
                    assignment: {
                        variableName: variable,
                        updatedValues,
                        isDefault: false,
                    },
                },
            },
            refreshBom: true,
            environment: {
                rootEnvironment: {
                    salesArea: {
                        distributionChannel: '01',
                        salesOrganization: params.plant || config.plant,
                    },
                    order: params.order,
                },
            },
        };
    }
}
